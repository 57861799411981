import { UPDATE_PROFILE,UPDATE_PROFILE_FAILURE } from "../actions/types";

const INITIAL_STATE = {
  updateErrorMessage: null,
  update: null
};
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
  case UPDATE_PROFILE:
    return {
      ...state,
      updateErrorMessage: null,
      update: payload
    };
  case UPDATE_PROFILE_FAILURE:
    return {
      ...state,
      updateErrorMessage: payload,
      update: null
    };
  default:
    return state;
  }
};
