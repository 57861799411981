import {
  PROJECT_PARAMETERS,
  PROJECT_PARAMETERS_FAILURE
} from "../actions/types";

const INITIAL_STATE = {
  parameters: [],
  parameters_error_message: ""
};
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
  case PROJECT_PARAMETERS:
    return {
      ...state,
      parameters: payload,
      parameters_error_message: null
    };
  case PROJECT_PARAMETERS_FAILURE:
    return {
      ...state,
      parameters: [],
      parameters_error_message: payload
    };
  default:
    return state;
  }
};
