import {
  FAQ,
  FAQ_FAILURE
} from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  errorMessage: null,
  data: null
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
  case FAQ:
    return { ...state, loading: false, data: payload };
  case FAQ_FAILURE:
    return { ...state, loading: false, errorMessage: payload };
  default:
    return state;
  }
};
