import {PRICE_CARDS, PRICE_CARDS_FAILURE} from "../actions/types";

const INITIAL_STATE = {
  price_cards_data: []
};
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
  case PRICE_CARDS:
    const price_cards_data = payload;
    return { ...state, price_cards_data };
  case PRICE_CARDS_FAILURE:
    return { ...state, price_cards_data: payload };
  default:
    return state;
  }
};
