import { NEW_PROJECT } from "../actions/types";

const INITIAL_STATE = {
  project: []
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case NEW_PROJECT:
    return { ...state, project: action.payload };
  default:
    return state;
  }
};
