import {
  CALCULATION_TYPES_FAILURE,
  CALCULATION_TYPES
} from "../actions/types";

const INITIAL_STATE = {
  list: [],
  message: ""
};
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
  case CALCULATION_TYPES:
    return {
      ...state,
      list: payload,
      message: null
    };
  case CALCULATION_TYPES_FAILURE:
    return {
      ...state,
      list: [],
      message: payload
    };
  default:
    return state;
  }
};
