import {  } from "../actions/types";
import {TOKEN_TO_PRICE_FAILURE} from "../actions/types";
import {TOKEN_TO_PRICE} from "../actions/types";

const INITIAL_STATE = {
  token_data: "",
  token_data_error: ""
};
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
  case TOKEN_TO_PRICE:
    return {
      ...state,
      token_data: payload,
      token_data_error: null
    };
  case TOKEN_TO_PRICE_FAILURE:
    return {
      ...state,
      token_data: null,
      token_data_error: payload
    };
  default:
    return state;
  }
};
