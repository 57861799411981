import { TOKEN, TOKEN_VERIFY_SUCCESS } from "../actions/types";

const INITIAL_STATE = {
  data:"",
  confirmationErrorMessage:""
};
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
  case TOKEN:
    return { ...state,confirmationErrorMessage:null, data:payload };
  case TOKEN_VERIFY_SUCCESS:
    return { ...state, confirmationErrorMessage:payload ,data:null};
  default:
    return state;
  }
};
