import {
    CREATE_PLANNED_CALCULATION,
    CREATE_PLANNED_CALCULATION_FAILURE,
    TRIGGER_PLANNED_CALCULATION,
    TRIGGER_PLANNED_CALCULATION_FAILURE
} from "../actions/types";

const INITIAL_STATE = {
    addErrorMessage: null,
    calculation: null,
    list: []
};
export default (state = INITIAL_STATE, action) => {
    const {type, payload} = action;
    switch (type) {
        case CREATE_PLANNED_CALCULATION:
            return {
                ...state,
                addErrorMessage: null,
                calculation: payload,
                list: [...state.list, payload]
            };
        case TRIGGER_PLANNED_CALCULATION:
            return {};
        case TRIGGER_PLANNED_CALCULATION_FAILURE:
            return {
                ...state,
                addErrorMessage: payload,
            };
        case CREATE_PLANNED_CALCULATION_FAILURE:
            return {
                ...state,
                addErrorMessage: payload,
                calculation: null
            };
        default:
            return state;
    }
};
