import {
  CONTACT_MESSAGE, CONTACT_MESSAGE_FAILURE
} from "../actions/types";

const INITIAL_STATE = {
  error: null
};
export default (state = INITIAL_STATE, action) => {
  const {type} = action;
  switch (type) {
  case CONTACT_MESSAGE:
    return {
      ...state,
      error: true
    };
  case CONTACT_MESSAGE_FAILURE:
    return {
      ...state,
      error: false
    };
  default:
    return state;
  }
};
