export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_SUB_LAYOUT = 'CHANGE_SUB_LAYOUT';
export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const RESET = 'RESET';
export const NAV_BACK_COLOR = 'NAV_BACK_COLOR';
export const NAV_BRAND_COLOR = 'NAV_BRAND_COLOR';
export const HEADER_BACK_COLOR = 'HEADER_BACK_COLOR';
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const NAV_FIXED_LAYOUT = 'NAV_FIXED_LAYOUT';
export const HEADER_FIXED_LAYOUT = 'HEADER_FIXED_LAYOUT';
export const BOX_LAYOUT = 'BOX_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const OPEN_NOTIFICATIONS = 'OPEN_NOTIFICATIONS';

// REDUX TEST
export const REDUX_TEST_REQUEST = 'REDUX_TEST_REQUEST';
export const REDUX_TEST_SUCCESS = 'REDUX_TEST_SUCCESS';
export const REDUX_TEST_FAILURE = 'REDUX_TEST_FAILURE';

export const PRICE_CARDS = 'PRICE_CARDS';
export const PRICE_CARDS_FAILURE = 'PRICE_CARDS_FAILURE';

export const FAQ = 'FAQ';
export const FAQ_FAILURE = 'FAQ_FAILURE';

export const PROJECT = 'PROJECT';
export const PROJECT_FAILURE = 'PROJECT_FAILURE';

export const PROJECT_DETAIL = 'PROJECT_DETAIL';
export const PROJECT_DETAIL_FAILURE = 'PROJECT_DETAIL_FAILURE';

export const CALCULATION_TYPES = 'CALCULATION_TYPES';
export const CALCULATION_TYPES_FAILURE = 'CALCULATION_TYPES_FAILURE';


export const PROJECT_PARAMETERS = 'PROJECT_PARAMETERS';
export const PROJECT_PARAMETERS_FAILURE = 'PROJECT_PARAMETERS_FAILURE';

export const SETTINGS = 'SETTINGS';
export const SETTINGS_FAILURE = 'SETTINGS_FAILURE';

export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE';

export const CREATE_PLANNED_CALCULATION = 'CREATE_PLANNED_CALCULATION';
export const CREATE_PLANNED_CALCULATION_FAILURE = 'ACREATE_PLANNED_CALCULATION_FAILURE';

export const TRIGGER_PLANNED_CALCULATION = 'TRIGGER_PLANNED_CALCULATION';
export const TRIGGER_PLANNED_CALCULATION_FAILURE = 'TRIGGER_PLANNED_CALCULATION_FAILURE';

export const NEW_PROJECT = 'NEW_PROJECT';
export const METOCEAN = 'METOCEAN';
export const METOCEAN_FAILURE = 'METOCEAN_FAILURE';

export const SOIL_PARAMETERS = 'SOIL_PARAMETERS';

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const LOGIN = 'LOGIN';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const CONFIRMATION = 'CONFIRMATION';
export const CONFIRMATION_FAILURE = 'CONFIRMATION_FAILURE';


export const TOKEN = 'TOKEN';
export const TOKEN_VERIFY_SUCCESS = 'TOKEN_VERIFY_SUCCESS';
export const TOKEN_VERIFY_FAILURE = 'TOKEN_VERIFY_FAILURE';


export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const NEW_PASSWORD_RESET = 'NEW_PASSWORD_RESET';
export const NEW_PASSWORD_RESET_FAILURE = 'NEW_PASSWORD_RESET_FAILURE';


export const CONTACT = 'CONTACT';
export const CONTACT_FAILURE = 'CONTACT_FAILURE';

export const CONTACT_MESSAGE = 'CONTACT_MESSAGE';
export const CONTACT_MESSAGE_FAILURE = 'CONTACT_MESSAGE_FAILURE';

export const PROFILE = 'CONTACT_FAILURE';
export const PROFILE_FAILURE = 'CONTACT_FAILURE';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const UPDATE_PROFILE_PICTURE = 'UPDATE_PROFILE_PICTURE';
export const UPDATE_PROFILE_PICTURE_FAILURE = 'UPDATE_PROFILE_PICTURE_FAILURE';


export const TOKEN_TO_PRICE = 'TOKEN_TO_PRICE';
export const TOKEN_TO_PRICE_FAILURE = 'TOKEN_TO_PRICE_FAILURE';

export const CALCULATION_AMOUNTS = 'CALCULATION_AMOUNTS';
export const CALCULATION_AMOUNTS_FAILURE = 'CALCULATION_AMOUNTS_FAILURE';

export const GET_PARAMETERS = 'GET_PARAMETERS';
export const GET_PARAMETERS_SUCCESS = 'GET_PARAMETERS_SUCCESS';

export const BILLING_INFO = 'BILLING_INFO';
export const BILLING_INFO_FAILURE = 'BILLING_INFO_FAILURE';

export const NOTIFICATIONS = 'NOTIFICATIONS';
export const NOTIFICATIONS_FAILURE = 'NOTIFICATIONS_FAILURE';

export const INVOICE_ADDRESS_SAVE = 'INVOICE_ADDRESS_SAVE';
export const INVOICE_ADDRESS_SAVE_FAILURE = 'INVOICE_ADDRESS_SAVE_FAILURE';

export const CHANGE_SUBSCRIPTION_TYPE = 'CHANGE_SUBSCRIPTION_TYPE';
export const CHANGE_SUBSCRIPTION_TYPE_FAILURE = 'CHANGE_SUBSCRIPTION_TYPE_FAILURE';

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';
