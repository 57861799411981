import {PROFILE, PROFILE_FAILURE} from "../actions/types";

const INITIAL_STATE = {
  profile_data:
        {
          type: {
            name: 'Free'
          }
        }
};
export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
  case PROFILE:
    const profile_data = payload && payload.length ? payload[0] : {};
    return {...state, profile_data};
  case PROFILE_FAILURE:
    return {...state, profile_data: payload};
  default:
    return state;
  }
};
