import {
  REDUX_TEST_SUCCESS,
  REDUX_TEST_FAILURE
} from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  errorMessage: null,
  data: null
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
  case REDUX_TEST_SUCCESS:
    return { ...state, loading: false, data: payload };
  case REDUX_TEST_FAILURE:
    return { ...state, loading: false, errorMessage: payload };
  default:
    return state;
  }
};
