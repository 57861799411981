import {
  NOTIFICATIONS,
  NOTIFICATIONS_FAILURE
} from "../actions/types";

const INITIAL_STATE = {
  error: null,
  notifications: null
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
  case NOTIFICATIONS:
    return {...state, notifications: payload};
  case NOTIFICATIONS_FAILURE:
    return {...state, error: payload.error};
  default:
    return state;
  }
};
