import { LOGIN, LOGIN_FAILURE } from "../actions/types";

const INITIAL_STATE = {
  loginErrorMessage: null,
  token: null
};
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
  case LOGIN:
    return {
      ...state,
      loginErrorMessage: null,
      token: payload.token,
    };
  case LOGIN_FAILURE:
    return {
      ...state,
      loginErrorMessage: payload,
      token: null
    };
  default:
    return state;
  }
};
