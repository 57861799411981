import { REGISTER_SUCCESS, REGISTER_FAILURE } from "../actions/types";

const INITIAL_STATE = {
  register_data: "",
  registerErrorMessage: ""
};
export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
  case REGISTER_SUCCESS:
    return {
      ...state,
      register_data: payload,
      registerErrorMessage: null
    };
  case REGISTER_FAILURE:
    return {
      ...state,
      register_data: null,
      registerErrorMessage: payload
    };
  default:
    return state;
  }
};
