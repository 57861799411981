import {
  SETTINGS,
  SETTINGS_FAILURE
} from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  errorMessage: null,
  data: null
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
  case SETTINGS:
    return { ...state, loading: false, data: payload.data };
  case SETTINGS_FAILURE:
    return { ...state, loading: false, errorMessage: payload };
  default:
    return state;
  }
};
