import { FORGOT_PASSWORD, FORGOT_PASSWORD_FAILURE } from "../actions/types";

const INITIAL_STATE = {
  reset: "",
  resetErrorMessage: null
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
  case FORGOT_PASSWORD:
    return {
      ...state,
      resetErrorMessage: null,
      reset: payload.status
    };
  case FORGOT_PASSWORD_FAILURE:
    return {
      ...state,
      resetErrorMessage: payload.data.detail
    };
  default:
    return state;
  }
};
