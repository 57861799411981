import {
  ADD_PROJECT,
  ADD_PROJECT_FAILURE,
  PROJECT,
  PROJECT_DETAIL,
  PROJECT_DETAIL_FAILURE,
  PROJECT_FAILURE
} from "../actions/types";

const INITIAL_STATE = {
  projects: [],
  project: {},
  addErrorMessage: null,
  errorMessage: "",
  ProjectDetailData: {},
  DetailErrorMessage: ""
};
export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
  case PROJECT_DETAIL:
    return {...state, ProjectDetailData: payload, DetailErrorMessage: null};
  case PROJECT_DETAIL_FAILURE:
    return {...state, ProjectDetailData: payload, DetailErrorMessage: payload};
  case PROJECT:
    return {...state, projects: payload, errorMessage: null};
  case PROJECT_FAILURE:
    return {...state, projects: [], errorMessage: payload};
  case ADD_PROJECT:
    return {
      ...state,
      addErrorMessage: null,
      project: payload
    };
  case ADD_PROJECT_FAILURE:
    return {
      ...state,
      addErrorMessage: payload,
      project: null
    };
  default:
    return state;
  }
};
