import {
  CALCULATION_AMOUNTS,
  CALCULATION_AMOUNTS_FAILURE,
} from "../actions/types";

const INITIAL_STATE = {
  error: null,
  data: null
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
  case CALCULATION_AMOUNTS:
    return {...state, data: payload.data};
  case CALCULATION_AMOUNTS_FAILURE:
    return {...state, error: payload.error};
  default:
    return state;
  }
};
