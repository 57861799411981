import React from "react";

const SignUp = React.lazy(() =>
  import("./App/Pages/Authentication/SignUp/SignUp")
);

const Signin = React.lazy(() =>
  import("./App/Pages/Authentication/SignIn/SignIn")
);
const ConfirmationPage = React.lazy(() =>
  import("./App/Pages/Authentication/Confirmation/Confimation")
);

const ResetPassword = React.lazy(() =>
  import("./App/Pages/Authentication/ResetPassword/ResetPassword")
);

const NewPassword = React.lazy(() =>
  import("./App/Pages/Authentication/NewPassword/NewPassword")
);

const ChangePassword = React.lazy(() =>
  import("./App/Pages/Authentication/ChangePassword")
);
const ProfileSettings = React.lazy(() =>
  import("./App/Pages/Authentication/ProfileSettings")
);
const TabsAuth = React.lazy(() =>
  import("./App/Pages/Authentication/TabsAuth")
);
const Error = React.lazy(() => import("./Demo/Maintenance/Error"));
const OfflineUI = React.lazy(() => import("./Demo/Maintenance/OfflineUI"));
const ComingSoon = React.lazy(() => import("./Demo/Maintenance/ComingSoon"));

const route = [
  { path: "/auth/signup", exact: true, name: "Signup 2", component: SignUp },

  { path: "/auth/signin", exact: true, name: "Signin 2", component: Signin },
  {
    path: "/Confimation",
    exact: true,
    name: "Confirmation Page",
    component: ConfirmationPage
  },

  {
    path: "/auth/reset-password",
    exact: true,
    name: "Reset Password 2",
    component: ResetPassword
  },
  {
    path: "/auth/change-password",
    exact: true,
    name: "Change Password",
    component: ChangePassword
  },
  {
    path: "/auth/new-password",
    exact: true,
    name: "New Password",
    component: NewPassword
  },
  {
    path: "/auth/profile-settings",
    exact: true,
    name: "Profile Settings",
    component: ProfileSettings
  },
  {
    path: "/auth/tabs-auth",
    exact: true,
    name: "Tabs Authentication",
    component: TabsAuth
  },
  { path: "/maintenance/error", exact: true, name: "Error", component: Error },
  {
    path: "/maintenance/coming-soon",
    exact: true,
    name: "Coming Soon",
    component: ComingSoon
  },
  {
    path: "/maintenance/offline-ui",
    exact: true,
    name: "Offline UI",
    component: OfflineUI
  }
];

export default route;
