import {combineReducers} from 'redux';
import reducer from './reducer';
import calculationTypes from './calculationTypes';
import reduxtestReducer from './reduxtestReducer';
import Process from './processReducer';
import loginReducer from './loginReducer';
import registerReducer from './registerReducer';
import confirmation from './confirmation';
import projects from './projects';
import projectParametersReducer from './projectParametersReducer';
import settings from './settings';
import passwordReducer from './passwordResetReducer';
import newPasswordReducer from "./newPasswordReducer";
import profileReducer from "./profileReducer";
import updateProfileReducer from "./updateProfileReducer";
import updateProfilePictureReducer from "./updateProfilePictureReducer";
import confirmationReducer from './confirmationReducer';
import faqReducer from './faqReducer';
import createPlannedCalculation from './createPlannedCalculation';
import priceCardsReducer from "./priceCardsReducer";
import forgotPasswordReducer from "./forgotPasswordReducer";
import tokenToPriceReducer from "./tokenToPriceReducer";
import calculationAmountsReducer from "./calculationAmountsReducer";
import billingInfoReducer from "./billingInfoReducer";
import notificationsReducer from "./notificationsReducer";
import contactFormReducer from "./contactMessage";


export default combineReducers({
  reducer,
  reduxtestReducer,
  Process,
  loginReducer,
  registerReducer,
  confirmation,
  projects,
  projectParametersReducer,
  passwordReducer,
  calculationTypes,
  newPasswordReducer,
  profileReducer,
  updateProfileReducer,
  updateProfilePictureReducer,
  confirmationReducer,
  settings,
  priceCardsReducer,
  faqReducer,
  forgotPasswordReducer,
  createPlannedCalculation,
  tokenToPriceReducer,
  calculationAmountsReducer,
  billingInfoReducer,
  notificationsReducer,
  contactFormReducer
});

