import React, { Component, Suspense } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import Loadable from "react-loadable";
import "../../node_modules/font-awesome/scss/font-awesome.scss";
import Loader from "./layout/Loader";
import ScrollToTop from "./layout/ScrollToTop";
import routes from "../route";
import { LOGIN } from "../store/actions/types";
import { fetchingSuccess } from "../store/actions/handler";
import {connect} from "react-redux";

const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader
});


class App extends Component {

  render() {
    const {fetchingSuccess} = this.props;
    const token = localStorage.getItem('token');
    if(token)
      fetchingSuccess(LOGIN, {token});

    const menu = routes.map((route, index) => {
      return route.component ? (
        <Route
          key={index}
          path={route.path}
          exact
          name={route.name}
          render={props => <route.component {...props} />}
        />
      ) : null;
    });

    return (
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Switch>
            {menu}
            <Route path="/" component={AdminLayout} />
          </Switch>
        </Suspense>
      </ScrollToTop>
    );
  }
}



const mapDispatchToProps = (dispatch)=> ({
  fetchingSuccess: (type, payload) => dispatch(fetchingSuccess(type, payload) )
});


export default withRouter(connect(
  null,mapDispatchToProps
)(App));
